import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import FluidTracedImage from "./fluidTracedImage"

const Sidebar = ({ siteTitle }) => {
  return (
    <aside
      style={{
        borderRight: `1px solid #d40000`,
        display: "flex",
        flexDirection: "column",
        flexGrow: 0,
        flexShrink: 0,
        height: "100vh",
        padding: "45px",
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
        width: "350px",
      }}
    >
      <div style={{ marginBottom: "45px", width: "75%" }}>
        <Link to="/">
          <FluidTracedImage path="logo.png" />
        </Link>
      </div>
      <nav>
        <p>
          <Link to="/">Home</Link>
          <Link to="/about/">About</Link>
          <Link to="/contact/">Contact</Link>
          <Link to="/blog/">Blog</Link>
        </p>
        <p>
          <Link to="/pi-nonograms/">
            Play free <span class="pi_nonograms">&#960;</span> nonograms
          </Link>
        </p>
      </nav>
    </aside>
  )
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
